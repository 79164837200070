<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.title"
                placeholder="标题"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-select
                style="width: 150px"
                placeholder="类型"
                v-model="form.type"
              >
                <a-select-option
                  v-for="item in honestType"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker v-model="form.date" style="width: 240px" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button
              v-if="$getPermission($route.path + '/add')"
              @click="$router.push($route.path + '/add')"
              type="primary"
              >创建公告</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="公告标题">
          <template slot-scope="text">
            <a href="#" @click.prevent="preview(text)">
              {{ text.title }}
            </a>
          </template>
        </a-table-column>
        <a-table-column title="公告类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="honestType" :dictValue="text.type" />
          </template>
        </a-table-column>

        <a-table-column title="阅读量" align="center">
          <template slot-scope="text">
            {{ text.views ? text.views : 0 }}
          </template>
        </a-table-column>
        <a-table-column title="来源" data-index="source" align="center">
        </a-table-column>
        <a-table-column title="发布时间" data-index="publishAt" align="center">
        </a-table-column>

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="preview(text)">查看</a>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="edit(text)"
                >编辑</a
              >
              <a
                href="#"
                v-if="$getPermission($route.path + '/delete')"
                @click.prevent="deleteText(text)"
                class="danger"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import { fetchList, remove } from "@/api/party";
import { mapGetters } from "vuex";
export default {
  mixins: [watermark],

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    honestType() {
      return this.findDataDict("honestType");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      const { date } = form;
      let startDate, endDate;
      if (Array.isArray(date) && date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[1].format("YYYY-MM-DD");
      }
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
        date: undefined,
        startDate,
        endDate,
        category: "discipline",
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    preview(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    },
    edit(text) {
      this.$router.push(this.$route.path + "/edit?id=" + text.id);
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>